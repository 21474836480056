import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Footer = ({}) => {

  return  <>
  <div
  className='top-bar container-fluid text-white pt-4 pb-1'
  style={{ backgroundColor: '#344A5D' }}
>

<div className='text-center' style={{fontSize: '20px'}}><span className='me-3'>8101 Delmar Blvd., St. Louis, MO 63130</span> | <span className='mx-3'>314-727-1880</span> | <span className='ms-3'>info@youngisrael-stl.org</span></div>
<hr />
<Row className='text-center'>
<span>Copyright © 2025 Young Israel of St. Louis. All rights reserved.<a href='/admin' className='ms-2'>Admin</a></span></Row>
</div>
</>
}

export default Footer