import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import aboutBanner from '../../../assets/images/about-banner.jpg';
import oldBuilding from '../../../assets/images/old-building.jpg';
import rabbiShulman from '../../../assets/images/shulman2.jpg';
import shulmanMilah from '../../../assets/images/shulmanmilah2.jpg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';

const About = () => {
  const { currentTab } = useParams();
  // const [activeTabName, setActiveTabName] = useState(currentTab);
  const [activeTabIndex, setActiveTabIndex] = useState();

  const tabs = ['', 'history', 'rabbi', 'membership', 'board', 'contact'];

  const boardColumns = [
    { position: 'President', names: ['Max Oppenheimer'] },
    { position: 'Immediate Past President', names: ['Jonty Felsher'] },
    { position: 'Vice President', names: ['Elissa Schachter'] },
    { position: "President, Women's Division", names: ['Sabrina Gornish'] },
    { position: 'Secretary', names: ['Jackie Zitin'] },
    { position: 'Treasurer', names: ['Eldad Bialecki'] },
    {
      position: 'Trustee',
      names: ['Andy Koshner', 'Mark Friedman', 'Rose Ann Ariel']
    },
    { position: 'Building and Infrastructure Chair', names: ['Evan Axelbaum'] },
    { position: 'Membership Committee Chair', names: ['Aliza Freilich'] },
    { position: 'Security Committee Chair', names: ['Seth Near'] },
    {
      position: 'Gabbai',
      names: ['Daniel Siegel', 'Danny Wachsstock', 'Elliott Franklin']
    }
  ];

  useEffect(() => {
    setActiveTabIndex(
      !currentTab ? 0 : tabs.findIndex((el) => el === currentTab)
    );
  }, []);

  const handleTabChange = (newTabIndex) => {
    setActiveTabIndex(newTabIndex);
    if (newTabIndex === 0) {
      window.history.replaceState(null, null, '/about');
    } else {
      window.history.replaceState(null, null, `/about/${tabs[newTabIndex]}`);
    }
  };

  return (
    <>
      <div className='page-header text-center d-flex container-fluid justify-content-center align-items-center'>
        <h1>About YI</h1>
      </div>
      <TabView
        className='mt-1 nav-ribbon'
        activeIndex={activeTabIndex}
        onTabChange={(e) => handleTabChange(e.index)}
        scrollable
      >
        <TabPanel header='About YI'>
          <Container>
            <img src={aboutBanner} className='float-start me-3' style={{}} />
            <p>
              Young Israel of St. Louis is an Orthodox synagogue affiliated with
              the National Council of Young Israel that provides religious
              services and Torah study for its members. Its Women's Division
              organizes a variety of social and learning activities and is
              involved in many charitable causes. Guest speakers, classes in a
              wide range of Jewish subjects and lecture series are open to the
              entire community. The synagogue has an active chesed committee,
              which helps people who require any assistance.
            </p>
            <p>
              Our 160 member families have actively led and participated in many
              Jewish communal and educational institutions including the Jewish
              Federation, the JCC, the Jewish Center for the Aged, the Vaad
              Hoeir, Covenant House, St. Louis Hillel at Washington University,
              Torah Mitzion Kollel, Yeshivat Kadimah High School and the Epstein
              Hebrew Academy. Two of its members have been president of the
              Jewish Federation of Saint Louis. The Community Initiative to
              Strengthen Families brings speakers and organizes programs to
              promote shalom bayis. The State of Israel figures proudly in
              synagogue programs, run in conjunction with the Kollel Torah
              MiTzion.
            </p>{' '}
            <p>
              {' '}
              Activities for Young Israel youth include Shabbos groups for
              children, a teen minyan, an innovative Bas Mitzvah program, Sunday
              morning Derech Eretz clubs and the city-wide Junior and Senior
              NCSY.
            </p>
          </Container>
        </TabPanel>
        <TabPanel header='History'>
          <Container>
            <h2 className='text-center fw-bold mb-4'>
              The History of the Young Israel of St. Louis
            </h2>
            <img src={oldBuilding} className='float-start me-3' style={{}} />
            <p>
              The Young Israel of St. Louis has been a revered constant in the
              Orthodoxy community for over 75 years and stands as a testament to
              the drive and devotion of its founders and vigor of its present
              membership. Throughout its history, the shul has played a vital
              part in the religious life of the entire city, contributing
              energy, funding and time to the larger Jewish community. When
              speaking of St. Louis as home, many are referring to the Young
              Israel of St. Louis.
            </p>
            <p>
              Young Israel of St. Louis was started just before the beginning of
              World War II, by a cadre of committed young people. The group
              davened and started youth groups and an assortment of fundraising
              activities. As the shul developed, others joined them; young
              women, single and married, played an important role in developing
              the character of the organization. In the early 1940's, as many of
              the young men left to fight in the war, the minyan itself was
              suspended, but the organization remained vibrant. Social and
              fundraising efforts kept the fledgling operation afloat. With the
              return of the men after World War II, the membership grew and
              affiliation with the National Council of Young Israel strengthened
              the group. Soon the shul became a hub for observant families,
              attracting many couples who had grown up in Young Israel
              synagogues in other communities. The services were characterized
              by singing and an intelligent, challenging drasha. Some, drawn in
              by the shul's welcome and tune-filled services, stayed and set
              down roots.
            </p>
            <p>
              The shul's first official rabbi was Rabbi Gerald Jacobs. He was
              followed by Rabbi Pelberg and Rabbi Yitzchok Abramson. After
              several moves that included a stint in the attic of another
              Orthodox shul as well as a room above a delicatessen, the group
              built its own permanent facility on Groby Road in University City.
              In 1969, Rabbi Simcha Krauss was hired as the new rabbi, and the
              shul developed further as a well-educated, deeply committed group
              of people. Young Israel welcomed newcomers with warmth and an
              opportunity to learn and grow religiously. The constants
              throughout were always the friendships, the programs and the
              learning.
            </p>
            <p>
              In the 1980's the shul underwent a major change when Rabbi Krauss
              left for the Young Israel of Hillcrest, NY and Rabbi Jeffery
              Bienenfeld assumed the leadership. St. Louis has always had a
              large number of Orthodox shuls, but Young Israel represented a
              spirit of energy and innovation. Under Rabbi Bienenfeld's
              tutelage, the shul broadened its learning opportunities. It
              attracted a number of newly observant families and weekly classes
              increased for both men and women.
            </p>
            <p>
              In the early 1990's with the change in the neighborhood and an
              influx of new families, the leadership of the shul recognized the
              need for a new location that would better suit the burgeoning
              membership. This endeavor sparked an outpouring of support and
              translated into a renewed zeal and enthusiasm for the shul. The
              entire community turned out for the parade that brought the Sifrei
              Torah into the new building, fittingly, on Erev Shavuot, 1994.
            </p>
            <p>
              In 2007 Rabbi Bienenfeld and his family decided to fulfill their
              life-long dream and made Aliyah. His successor, Rabbi Moshe
              Shulman, together with his family, continue to lead our
              congregation with the same zeal for learning and communal
              leadership that has been the hallmark of the rabbinic leadership
              of Young Israel from its early days.
            </p>
            <p>
              Today, on a Shabbat morning at Young Israel, in additional to the
              normal minyan infused with meaningful prayer and Torah study,
              you'll find a dynamic hashkama minyan, an energetic teen minyan,
              youth groups for all ages, and a well attended Kiddush where
              members can socialize and strengthen relationships. You’ll find
              learning and shiurim before davening Shabbat morning as well as
              Shabbat afternoon, given both by Rabbis and learned lay-leaders.
              Every day of the week, in addition to daily minyan, there are
              ongoing regularly attended shiurim, morning and evening. At
              Thursday night Mishmar and Sunday morning after minyan the Beit
              Midrash is filled with the voice of Torah study of men and women,
              teens and adults. Our Scholar in Residence program brings the
              community together to enhance the opportunities for study and
              reflection on broad relevant issues facing the Jewish world.
            </p>
            <p>
              Our day schools, Epstein Hebrew Academy and Yeshivat Kadimah High
              School, youth movements such as NCSY and Bnei Akiva, and community
              service organizations such as Torah Mitzion, and the Bnot Sherut
              all find their spiritual and communal center of gravity within the
              active membership of our Young Israel.
            </p>
            <p>
              In recent years, young families have begun to return to St. Louis
              and set down roots. This renewed youthfulness and vitality in the
              Syangogue is evidenced in the number of baby strollers that can be
              seen in the parking lot on Shabbat morning!
            </p>
            <p>
              In short, the Young Israel of St. Louis is a vibrant, dynamic shul
              which is the spiritual, communal and intellectual center of our
              community. It is more than just a Beit Tefillah (House of Prayer)
              or a Beit Midrash (House of Learning). It is a true Beit Knesset,
              a center for the gathering of the “community of Israel”.
            </p>
            <p>
              The Young Israel of St. Louis is a place of many generations of
              people, from those who were here at its beginnings before World
              War II, to the newly married who arrived just a few months ago.
              All are welcome. Young Israel seeks to make them part of the
              adventure of Torah growth, love for Israel and community
              commitment that has flourished here in the friendly, gentle-paced
              Midwest.
            </p>
          </Container>
        </TabPanel>

        <TabPanel header='Meet The Rabbi'>
          <Container>
            <h2 className='text-center fw-bold mb-4'>Rabbi Moshe Shulman</h2>
            <img src={rabbiShulman} className='float-start me-3' style={{}} />
            <p>
              Rabbi Shulman is a graduate of the Sha'alvim Rabbinic Kollel with
              Rabbinic Ordination from the Jerusalem Chief Rabbinate, and a
              graduate of Sha'alvim's Sha'al Institute for Rabbinic and
              Educational Training. He is an erudite and dynamic speaker with
              special interests in Judaism and Science, Medicine and Halacha,
              and Tanach, and has a deep and passionate love for Israel.
            </p>
            <p>
              He served as the spiritual leader of Congregation House of
              Jacob-Mikveh Israel in Calgary for five years and of Shaarei
              Shomayim Congregation in Toronto for ten years before coming to
              St. Louis.
            </p>
            <p>
              He holds a Teacher's Degree from the Sha'alvim Teacher's College
              and a B.Sc. in Computer Software Engineering from the Jerusalem
              College of Technology. He is also certified as a Rabbinic advisor
              to the organ donor community by the New York Organ Donation
              Network and the Halachic Organ Donation Society.
            </p>
            <img src={shulmanMilah} className='float-end ms-3' style={{}} />
            <p>
              <a href='https://milah.net' target='_blank'>
                Rabbi Shulman is a certified mohel
              </a>{' '}
              and trained under the renowned mohel of Jerusalem's Bikur Cholim,
              Misgav Ladach and Hadassah hospitals, Yosef Halpern, of blessed
              memory, as well as the Chief Rabbi of Jerusalem, Rav Yitzchak
              Kulitz.
            </p>
            <p>
              Rabbi Shulman grew up in New York and Los Angeles. His wife Baila
              comes from Sydney Australia. They have six children, Zipporah,
              Hadassah, Shmuel, Avital, Yechiel, and Galit. They joined the
              Young Israel family in August, 2007.
            </p>
          </Container>
        </TabPanel>
        <TabPanel header='Membership'>
          <Container>
            <h2 className='text-center fw-bold mb-4'>
              Interested In Becoming a Member?
            </h2>
            <p>
              If you are not already a member of our Shul, we invite you to join
              us. We offer reduced membership dues for new members in their
              first year of membership. Please contact the Rabbi at 314-727-1880
              for an introductory meeting.
            </p>
          </Container>
        </TabPanel>
        <TabPanel header='Meet The Board'>
          <Container>
            <DataTable
              className='mx-auto'
              value={boardColumns}
              style={{ maxWidth: '500px' }}
              stripedRows
              showHeaders={false}
            >
              <Column field='position' bodyClassName='fw-bold' />
              <Column
                body={(rowData) => {
                  return (
                    <ul style={{ listStyle: 'none' }}>
                      {rowData.names.map((n, i) => {
                        return <li key={i}>{n}</li>;
                      })}
                    </ul>
                  );
                }}
                bodyClassName='align-items-center'
              />
            </DataTable>
          </Container>
        </TabPanel>
        <TabPanel header='Contact Us'>
          <Container>
            <Row>
              <Col xs={4}>
                <p>
                  <b>We are conveniently located at:</b>
                </p>
                <p>
                  <span>8101 Delmar Blvd.</span> <br />{' '}
                  <span>St. Louis, MO 63130</span>{' '}
                </p>
                <br />
                <p>
                  <b>Email:</b> info@youngisrael-stl.org{' '}
                </p>
                <br />
                <p>
                  <b>Phone:</b> 314-727-1880
                </p>
              </Col>
              <Col xs={8}>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3115.494278382756!2d-90.3491829236376!3d38.66050716064618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87df34cfd581d1d3%3A0xc9493ca6d726ca6c!2sYoung%20Israel%20of%20St.%20Louis!5e0!3m2!1sen!2sus!4v1723065810532!5m2!1sen!2sus'
                  width='800'
                  height='450'
                  style={{ border: 0 }}
                  allowFullScreen=''
                  loading='lazy'
                  referrerPolicy='no-referrer-when-downgrade'
                ></iframe>
              </Col>
            </Row>
          </Container>
        </TabPanel>
      </TabView>
    </>
  );
};

export default About;
