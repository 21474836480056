import React from 'react';
import('./style.scss');
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Button } from 'primereact/button';
import PhotoCarousel from './PhotoCarousel';

const Home = () => {
  return (
    <>
    <div id='jumbotron' className='d-flex container-fluid justify-content-center align-items-center'>
        <div className='contrast-box text-center text-white p-4'><h1 className='pb-3' >Young Israel of St. Louis</h1>
        
        <h2>A Vibrant Modern Orthodox Shul</h2>
        
        </div>
    </div>
      {/* <section className='jumbotron-wrapper'>
        <div className='jumbotron-overlay d-flex justify-content-center align-items-center'>
        <div className='jumbotron-text-wrapper text-center px-5 mb-5'>
          <h1 className='mt-3'>
            Young Israel of St. Louis
          </h1>{' '}
          <br />
          <h2>A Vibrant Modern Orthodox Shul</h2>
        </div>
        </div>
      </section> */}
      <h2 className='text-center mt-4 mb-3 fw-bold'>
          Welcome to the Young Israel of St. Louis
        </h2>
      <PhotoCarousel />

      <Container className='px-5'>
      
        <p>
          Young Israel of St. Louis welcomes visitors and new members to our
          warm and vital shul. Committed to living Torah lives, fostering
          spiritual growth and supporting Israel, our Orthodox congregation
          provides daily and Shabbos davening as well as multiple learning
          opportunities for men, women and our youth. Located in the heart of
          the St. Louis eruv, Congregation Young Israel welcomes you.
        </p>
        <div className='text-center'>
          <Button onClick={() => window.location.href = '/about'} rounded severity='warning' label='More About Us' />
        </div>
        <Row>
          <Col>
            <h2 className='text-center mt-4 fw-bold'>
              Interested in Membership?
            </h2>
            <p>
              If you are not already a member of our Shul, we invite you to join
              us. We offer reduced membership dues for new members in their
              first year of membership. Please contact the Rabbi at 314-727-1880
              for an introductory meeting.
            </p>
          </Col>
          <Col>
            <h2 className='text-center mt-4 fw-bold'>Visiting St. Louis?</h2>
            <p>
              We strive to live by the principle of hachnassas orchim. If you
              will be visiting the Saint Louis community for business, vacation,
              or looking to relocate, we invite you to enjoy a wonderful Shabbos
              at our shul. Please contact the office at info@youngisrael-stl.org
              or (314) 727-1880 and we can help arrange hospitality and
              accomodations.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
