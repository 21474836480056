import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import Container from 'react-bootstrap/Container';
import { useParams } from 'react-router-dom';
import EventsCarousel from './EventsCarousel';

const Events = () => {
  const { currentTab } = useParams();
  const [activeTabIndex, setActiveTabIndex] = useState();
  const [loading, setLoading] = useState(false)
  const [events, setEvents] = useState([])

  const tabs = ['', 'pictures'];

  useEffect(() => {
    setActiveTabIndex(
      !currentTab ? 0 : tabs.findIndex((el) => el === currentTab)
    );
    fetchEvents()
  }, []);

  const handleTabChange = (newTabIndex) => {
    setActiveTabIndex(newTabIndex);
    if (newTabIndex === 0) {
      window.history.replaceState(null, null, '/events');
    } else {
      window.history.replaceState(null, null, `/events/${tabs[newTabIndex]}`);
    }
  };

  const fetchEvents = () => {
    setLoading(true)
    fetch('/api/events')
    .then(response => response.json())
    .then(payload => {
      console.log(payload);
      setEvents(payload.events)
      setLoading(false)
    })
  }

  return (
    <>
      <div className='page-header text-center d-flex container-fluid justify-content-center align-items-center'>
        <h1>Events</h1>
      </div>
      <TabView
        className='mt-1 nav-ribbon'
        activeIndex={activeTabIndex}
        onTabChange={(e) => handleTabChange(e.index)}
        scrollable
      >
        <TabPanel header='Events'>
          <Container>
            {events && <EventsCarousel  events={events} />}
          </Container>
        </TabPanel>
        <TabPanel header="Pictures">
          <Container>

          </Container>
        </TabPanel>
      </TabView>
    </>
  );
};

export default Events;
