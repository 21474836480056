import React, {useState, useEffect} from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { Dialog } from 'primereact/dialog'

const AnnouncementModal = ({visible, setVisible}) => {
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState()


  useEffect(() => {
    setLoading(true)
    fetch('/api/announcement')
    .then(response => response.json())
    .then(payload => {
      console.log(payload);
      setUrl(payload.weekly_announcement.url)
      setLoading(false)
    })
  },[])




return(
  <Dialog visible={visible} onHide={() => setVisible(false)}>
    {/* {loading ? <Spinner animation='border' /> : <> */}
    
      <iframe 
        src={url}
        width="800" height="600"
      />
    {/* </>} */}
  </Dialog>
)
}

export default AnnouncementModal