import React from 'react';
import { Carousel } from 'primereact/carousel';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Icon from '@mdi/react';
import { mdiCalendarMonthOutline } from '@mdi/js';

const EventsCarousel = ({ events }) => {
  const eventTemplate = (event) => {
    const eventDate = new Date(event.date);
    return (
      <Card>
        <Card.Body>
          <Row className='row-cols-2'>
            <Col>
              <Image
                src={event.url}
                fluid
                // style={{maxWidth: '500px'}}
              />
            </Col>
            <Col className='text-center'>
              <h2>{event.title}</h2>

              <p className='mt-5'>
                <Icon
                  path={mdiCalendarMonthOutline}
                  size={1.5}
                  className='me-2'
                />
                {event.date_only
                  ? eventDate.toLocaleString('en-us', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric'
                    })
                  : eventDate.toLocaleString('en-us', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: '2-digit'
                    })}
              </p>
              {event.description && <p>{event.description}</p>}
              {event.category && <p>{event.category}</p>}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <Carousel value={events} itemTemplate={eventTemplate} />
    </>
  );
};

export default EventsCarousel;
