import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import Container from 'react-bootstrap/Container';
import { useParams } from 'react-router-dom';
import archPicture from '../../../assets/images/arch.jpg';
import vaadPicture from '../../../assets/images/vaad.jpg';
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Community = () => {
  const { currentTab } = useParams();
  const [activeTabIndex, setActiveTabIndex] = useState();

  const tabs = ['', 'kosher', 'resources', 'visiting'  ];

  useEffect(() => {
    setActiveTabIndex(
      !currentTab ? 0 : tabs.findIndex((el) => el === currentTab)
    );
  }, []);

  const handleTabChange = (newTabIndex) => {
    setActiveTabIndex(newTabIndex);
    if (newTabIndex === 0) {
      window.history.replaceState(null, null, '/community');
    } else {
      window.history.replaceState(null, null, `/community/${tabs[newTabIndex]}`);
    }
  };

  return (
    <>
      <div className='page-header text-center d-flex container-fluid justify-content-center align-items-center'>
        <h1>Community</h1>
      </div>
      <TabView
        className='mt-1 nav-ribbon'
        activeIndex={activeTabIndex}
        onTabChange={(e) => handleTabChange(e.index)}
        scrollable
      >
        <TabPanel header='About The Community'>
          <Container>
            <Image src={archPicture} fluid style={{maxWidth: '400px'}} className='float-start me-3' />
            {/* <img src={archPicture} className='float-start me-3' style={{}} /> */}
              <p>From NHBZ site:  St. Louis boasts a strong Jewish community of approximately 60,000 people, and it offers an active and rich Jewish life in a warm, and child-friendly environment with numerous cultural, recreational, educational and employment opportunities, all in a low-cost, affordable, easy-to-live-in city. </p>
              <p>Job opportunities are plentiful in many industries - from Fortune 500 companies and tech startups to everything in between.  St. Louis is a hub for the financial service industry, is home to world famous hospitals and medical schools, and has growing biotechnology and geospatial technology districts.  The low cost of living, affordable housing, low taxes and short commute times mean more time for family and personal growth.   From the heart of the Jewish neighborhoods, it is 10 minutes to the airport and 15 minutes to most centers of employment.  After work, It is easy to make it home for dinner or to shul for minyan.</p>
              <p>The Orthodox community is well-established, with seven congregations, and religious amenities such as an eruv, mikvaot, and kosher restaurants.  The shuls offer a variety of style and character, as well as a range of activities.</p>
          </Container>
        </TabPanel>
    
        <TabPanel header='Kosher'>
          <Container>
            <h2 className='fw-bold text-center'>Vaad Hoeir of St. Louis</h2>
            <Image src={vaadPicture} fluid style={{width: '100px'}} className='float-start me-3' />
            <p><a target='_blank' href="https://ovkosher.org/">The Vaad Hoeir</a> has supplied the essential foundations for Jewish life in St. Louis for over 80 years. A staff of rabbinic coordinators supervises manufactured kosher products, all local kosher butchers, bakeries, and food establishments, and oversees the preparation of all local kosher catered events. The Vaad Hoeir is dedicated to maintaining local Shechita (kosher slaughter) providing fresh glatt kosher meat for the St. Louis community. The Vaad Hoeir certifies kosher products with its "OV" label. The Vaad inspects, supervises and certifies the ingredients and products of many nationally recognized companies. The Vaad Hoeir is a member of the Association of Kashrus Organizations.</p>
            <hr />
            <Row>
              <Col>
              <h3>Resturaunts:</h3>
              <ul>
                <li><a target='_blank' href="http://www.gokulrestaurant.com/">Gokul Indian Restaurant</a></li>
              </ul>
              <ul>
                <li><a target='_blank' href="https://teddrewes.com/">Ted Drewes Frozen Custard</a></li>
              </ul>
              <ul>
                <li>Elefant Pizza: (314) 513-8149</li>
              </ul>
              <ul>
                <li><a target='_blank' href="https://crowncenterstl.org/cafe/">Circle @ Crown Cafe</a></li>
              </ul>
              </Col>
              <Col>
              <h3>Supermarket and Shops:</h3>
              <ul>
                <li>Schnucks Supermarket (Ladue)</li>
              </ul>
              <ul>
                <li><a target='_blank' href="https://www.moundcity.com/">Mound City Shelled Nut Company</a></li>
              </ul>
              </Col>
            </Row>
            
          </Container>
        </TabPanel>
        <TabPanel header='Jewish Resources'>
          <Container>
            
          </Container>
        </TabPanel>
        <TabPanel header='Visiting St. Louis'>
          <Container>

          </Container>
        </TabPanel>
      </TabView>
    </>
  );
};

export default Community;
