import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';
const { GeoLocation, Zmanim } = require('@hebcal/core');

const Davening = () => {
  const { currentTab } = useParams();
  const [activeTabIndex, setActiveTabIndex] = useState();
  const [zmanim, setZmanim] = useState();

  const tabs = ['', 'zmanim'];

  useEffect(() => {
    setActiveTabIndex(
      !currentTab ? 0 : tabs.findIndex((el) => el === currentTab)
    );
    const latitude = 38.659448;
    const longitude = -90.3478282;
    const tzid = 'America/Chicago';
    // const friday = new Date(2023, 8, 8);
    const gloc = new GeoLocation(null, latitude, longitude, 0, tzid);
    setZmanim(new Zmanim(gloc, new Date(), true));
    // const candleLighting = zmanim.sunsetOffset(-18, true);
    // const timeStr = Zmanim.formatISOWithTimeZone(tzid, candleLighting);
  }, []);

  const handleTabChange = (newTabIndex) => {
    setActiveTabIndex(newTabIndex);
    if (newTabIndex === 0) {
      window.history.replaceState(null, null, '/davening');
    } else {
      window.history.replaceState(null, null, `/davening/${tabs[newTabIndex]}`);
    }
  };

  return (
    <>
      <div className='page-header text-center d-flex container-fluid justify-content-center align-items-center'>
        <h1>Davening</h1>
      </div>
      <TabView
        className='mt-1 nav-ribbon'
        activeIndex={activeTabIndex}
        onTabChange={(e) => handleTabChange(e.index)}
        scrollable
      >
        <TabPanel header='Schedule'>
          <Container className='justify-content-center'>
            <Row>
              <Col className='text-center'>
                <h2>
                  <b>Shabbos</b>
                </h2>
                {/* <Row> */}
                {/* <Col> */}
                <h4>
                  <u>Shacharis</u>
                </h4>
                <span>
                  <b>Hashkama:</b> 7:30 AM
                </span>{' '}
                <br />
                <span>
                  <b>Regular Minyan:</b> 9:00 AM
                </span>{' '}
                <br />
                <span>
                  <b>Teen Minyan:</b> 9:45 AM
                </span>{' '}
                <br />
                <span>
                  <b>Tot Shabbat (preschool) and youth groups (K-4):</b> 10:30
                  AM
                </span>{' '}
                <br /> <br />
                {/* </Col> */}
                {/* <Col> */}
                <h4>
                  <u>Mincha/Maariv</u>
                </h4>
                <span>
                  <b>Erev Shabbos (winter):</b> 5 minutes before candlelighting
                </span>{' '}
                <br />
                <span>
                  <b>Erev Shabbos (summer, DST):</b> 7:00 PM
                </span>{' '}
                <br />
                <span>
                  <b>Shabbos Day:</b> 35 minutes before sunset
                </span>
                {/* </Col> */}
                {/* </Row> */}
              </Col>
              <Col className='text-center'>
                <h2>
                  <b>Weekdays</b>
                </h2>
                <h4>
                  <u>Shacharis</u>
                </h4>
                <span>
                  <b>Sundays & Legal Holidays*</b> 8:00 AM
                </span>{' '}
                <br />
                <span>
                  <b>Mondays & Thursdays:</b> 6:30 AM
                </span>{' '}
                <br />
                <span>
                  <b>Tuesdays, Wednesdays, and Fridays:</b> 6:40 AM
                </span>{' '}
                <br />
                <br />
                {/* </Col> */}
                {/* <Col> */}
                <h4>
                  <u>Mincha/Maariv</u>
                </h4>
                <span>20 minutes before sunset</span>
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col className='text-center'>
                <h3>*Notes on Davening</h3>
                <ul style={{ listStyle: 'inside' }}>
                  <li>We use the RCA Artscroll Ashekenazi siddur.</li>
                  <li>
                    For Shabbos candlelighting times and exact times for mincha,
                    please see the bulletin/anouncements.
                  </li>
                  <li>
                    On days school is not in session (summer and Chol Hamoed)
                    there may be an additional Shacharis Shacharit minyan at 8
                    am; check the announcements.
                  </li>
                  <li>
                    {' '}
                    Legal Holidays: New Year's Day, Memorial Day, the Fourth of
                    July, Labor Day, Thanksgiving, and December 25th.
                  </li>
                  <li>
                    On weekdays when the Torah is read, Shacharis follows the
                    Monday-Thursday schedule.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </TabPanel>
        <TabPanel header='Zmanim'>
          <Container>
            {zmanim && (
              <>
                <h3 className='fw-bold'>
                  Important Zmanim for{' '}
                  {new Date().toLocaleDateString('en-US', {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                  :
                </h3>
                <ul className='mt-3'>
                  <li>
                    Alos:{' '}
                    {zmanim
                      .alotHaShachar()
                      .toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit'
                      })}
                  </li>
                  <li>
                    Sunrise:{' '}
                    {zmanim
                      .sunrise()
                      .toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit'
                      })}
                  </li>
                  <li>
                    Latest Sh'ma:{' '}
                    {zmanim
                      .sofZmanShma()
                      .toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit'
                      })}
                  </li>
                  <li>
                    Chatzos:{' '}
                    {zmanim
                      .chatzot()
                      .toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit'
                      })}
                  </li>
                  <li>
                    Mincha Gedola:{' '}
                    {zmanim
                      .minchaGedolaMGA()
                      .toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit'
                      })}
                  </li>
                  <li>
                    Plag HaMincha:{' '}
                    {zmanim
                      .plagHaMincha()
                      .toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit'
                      })}
                  </li>
                  <li>
                    Sunset:{' '}
                    {zmanim
                      .sunset()
                      .toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit'
                      })}
                  </li>
                  <li>
                    Tzeis:{' '}
                    {zmanim
                      .tzeit()
                      .toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit'
                      })}
                  </li>
                </ul>
              </>
            )}
          </Container>
        </TabPanel>
      </TabView>
    </>
  );
};

export default Davening;
